<template>
  <section class="carro-abandonado">
    <SpinnerLoading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-5">
            <router-link class="btn-text" to="/automatizaciones">
              <p class="link">{{ `<- Automatizaciones` }}</p>
            </router-link>
            <div>
              <h2 class="mb-4">
                Carro abandonado
                <span v-if="event_cart.event_name != ''"
                  >- {{ event_cart.event_name }}</span
                >
              </h2>
              <p class="mb-5">
                Crea un evento dentro de la categoría. Este según las
                condiciones que tenga activara las funciones del chatbot.
              </p>
            </div>
            <div>
              <div class="col-lg-12 px-0 buefy">
                <div
                  class="d-flex justify-content-between align-items-end mb-3"
                >
                  <label>Nombre del evento</label>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">Activo</span>
                    <b-switch v-if="event_cart" v-model="event_cart.active" />
                  </div>
                </div>
                <input
                  class="name-event"
                  type="text"
                  maxlength="50"
                  placeholder="Ej: Carro Abandonado 1"
                  v-model="event_cart.event_name"
                />
              </div>
              <div class="my-5">
                <label>Activar cuando:</label>
                <div class="mb-3">
                  <div class="cart-label-top">
                    <label class="label-cart"
                      >Fecha de abandono de carro
                    </label>
                  </div>
                  <div class="row dates-row py-3 activate-select">
                    <div class="d-flex flex-column col-lg-6">
                      <div>
                        <label> Después de: </label>
                        <select v-model="event_cart.type_search" class="w-100">
                          <option value>Seleccionar tiempo transcurrido</option>
                          <option
                            v-for="(date, index) in date_cart"
                            :key="date.text + index"
                            :value="date.value"
                            >{{ date.text }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div>
                        <label> Horario de envío entre:</label>
                        <select v-model="event_cart.hour_to_send" class="w-100">
                          <option value>Seleccionar el horario de envío</option>
                          <option
                            v-for="(time, index) in schedules"
                            :key="time.text + index"
                            :value="time.value"
                            >{{ time.text }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="cart-label-top">
                    <label class="label-cart">Precio del carrito </label>
                  </div>
                  <div class="row dates-row py-3 activate-select">
                    <div class="d-flex flex-column col-lg-6">
                      <div>
                        <label> Entre </label>
                        <input
                          type="number"
                          placeholder="1,000"
                          v-model.number="event_cart.min_price"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div>
                        <label> Hasta</label>
                        <input
                          type="number"
                          placeholder="100,000"
                          v-model.number="event_cart.max_price"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <label>Respuesta para el cliente:</label>
              </div>
              <div>
                <div
                  class="d-flex justify-content-between align-items-start mb-3"
                >
                  <label>Mensaje de respuesta:</label>
                  <div class="d-flex align-items-center">
                    <router-link to="/whatsapp-business">
                      <span class="link mr-2">Crear nuevo mensaje -></span>
                    </router-link>
                  </div>
                </div>
                <div
                  v-if="templates.length == 0"
                  class="info_box-event mb-5 mt-2"
                >
                  <div
                    class="row justify-content-center align-items-center mb-0 py-3"
                  >
                    <span
                      >Crea un mensaje de plantilla con el parametro de carro
                      abandonado</span
                    >
                  </div>
                </div>
                <div v-else>
                  <select
                    class="col-12"
                    v-model="selectedOption"
                    @change="handleSelectChange"
                  >
                    <option value="" disabled selected
                      >Selecciona un mensaje para enviar</option
                    >
                    <option
                      v-for="template in templates"
                      :key="template.id"
                      :value="template.id"
                      >{{ template.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between nav-section">
              <router-link class="btn-text" to="/automatizaciones">
                <p class="link">&lt;- Volver</p>
              </router-link>
              <button
                @click="saveCartEvent(id_event)"
                class="btn btn-base my-4"
              >
                Guardar
              </button>
            </div>
          </div>
          <div class="col-lg-4 justify-content-end d-flex mb-5">
            <EventCard
              :eventCreate="event_create"
              :disableLoadEvent="false"
              :idEvent="id_event"
              @loadEvent="loadEventById"
              @newEventReturn="newEventReturn"
            />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import dashboard_api from '../../dashboard_api.js';
import EventCard from '../../views/Automatizaciones/components/EventCard.vue';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      id_event: null,
      allTemplate: [],
      event_cart: {
        event_name: '',
        active: false,
        type_event: 'cart_abandoned',
        type_search: '',
        hour_to_send: '',
        min_price: '',
        max_price: '',
        bot_id: '',
      },
      payroll_message_id: [],
      payroll_messages: [],
      selectedOption: '',
      last_payroll_messages: '',
      event_create: [],
      templates: [],
      date_cart: [
        {
          text: '1 hora',
          value: '1-hour',
        },
        {
          text: '8 horas',
          value: '8-hours',
        },
        {
          text: '1 día',
          value: '1-day',
        },
        {
          text: '3 días',
          value: '3-days',
        },
        {
          text: '1 semana',
          value: 'week',
        },
        {
          text: '1 mes',
          value: 'month',
        },
      ],
      schedules: [
        {
          text: '08:00:00 - 09:00:00 hrs',
          value: '08',
        },
        {
          text: '09:00:00 - 10:00:00 hrs',
          value: '09',
        },
        {
          text: '10:00:00 - 11:00:00 hrs',
          value: '10',
        },
        {
          text: '11:00:00 - 12:00:00 hrs',
          value: '11',
        },
        {
          text: '12:00:00 - 13:00:00 hrs',
          value: '12',
        },
        {
          text: '13:00:00 - 14:00:00 hrs',
          value: '13',
        },
        {
          text: '14:00:00 - 15:00:00 hrs',
          value: '14',
        },
        {
          text: '15:00:00 - 16:00:00 hrs',
          value: '15',
        },
        {
          text: '16:00:00 - 17:00:00 hrs',
          value: '16',
        },
        {
          text: '17:00:00 - 18:00:00 hrs',
          value: '17',
        },
        {
          text: '18:00:00 - 19:00:00 hrs',
          value: '18',
        },
        {
          text: '19:00:00 - 20:00:00 hrs',
          value: '19',
        },
      ],
    };
  },

  components: {
    EventCard,
  },

  watch: {
    selected_bot: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getEvents(val);
          await this.getTemplate(val);
        }
      },
    },
  },

  computed: {
    ...mapState(['selected_bot']),
  },

  methods: {
    // Método para cargar la información del evento
    async loadEventById(id) {
      try {
        this.loading = true;
        const event = this.event_create.find(event => event.id === Number(id));
        if (!event) {
          this.event_cart = {
            event_name: '',
            active: false,
            type_event: 'cart_abandoned',
            type_search: '',
            hour_to_send: '',
            min_price: '',
            max_price: '',
            bot_id: '',
          };
          this.id_event = null;
          this.payroll_message_id = [];
          this.selectedOption = '';
          this.last_payroll_messages = '';
        } else {
          this.event_cart = event;
          this.id_event = Number(id);
          this.selectedOption = this.event_cart.events_payroll_messages[0].payroll_message_id;
          this.last_payroll_messages = this.event_cart.events_payroll_messages[0].payroll_message_id;
          console.log(this.event_cart);
        }
      } catch (error) {
        console.error('Error al cargar el evento:', error);
      } finally {
        this.loading = false;
      }
    },

    async getTemplate(bot) {
      const response = (
        await dashboard_api.get(`/payroll_messages/by_bot/${bot.id}`)
      ).data;
      this.allTemplate = response;
      if (response.length > 0) {
        this.templates = response.filter(item => {
          return item.components.some(component => {
            const customParams = component.custom_parameters;
            return (
              Array.isArray(customParams) &&
              customParams.includes('$lead.carro_abandonado')
            );
          });
        });
      }
    },

    // Obtiene todos los eventos
    async getEvents(bot) {
      try {
        this.loading = true;
        let event = await dashboard_api.get(`/events/events_by_bot/${bot.id}`);
        this.event_create = event.data.filter(
          eventItem => eventItem.type_event === 'cart_abandoned',
        );
        console.log(this.event_create);
        this.loading = false;
      } catch (err) {
        if (err.response.status != 200) {
          this.event_create = [];
        }
      }
    },

    handleSelectChange(event) {
      // Obtiene el valor seleccionado de la lista desplegable.
      const selectedValue = event.target.value;

      // Verifica si el valor seleccionado no está presente en el array payroll_message_id
      // y si la opción seleccionada (selectedOption) no está vacía.
      if (
        !this.payroll_message_id.includes(selectedValue) &&
        this.selectedOption !== ''
      ) {
        // Agrega el valor seleccionado al array payroll_message_id.
        this.payroll_message_id.push(selectedValue);
      }
    },

    validateForm() {
      if (this.event_cart.event_name === '') {
        this.showError('Ingrese nombre para el evento');
        return false;
      }

      if (this.event_cart.type_search === '') {
        this.showError('Ingrese el tiempo a transcurrir');
        return false;
      }

      if (this.event_cart.hour_to_send === '') {
        this.showError('Ingrese el tiempo de envío');
        return false;
      }

      if (this.event_cart.min_price === '') {
        this.showError('Ingrese el rango mínimo');
        return false;
      }

      if (this.event_cart.max_price === '') {
        this.showError('Ingrese el rango máximo');
        return false;
      }

      if (this.event_cart.max_price < this.event_cart.min_price) {
        this.showError('El rango mayor debe superar al mínimo');
        return false;
      }

      if (this.selectedOption === '') {
        this.showError('Debe elegir un mensaje de respuesta para enviar');
        return false;
      }

      return true;
    },

    showError(message) {
      this.$swal({
        icon: 'error',
        title: message,
      });
    },

    // Salvar el evento
    saveCartEvent(id) {
      const self = this;

      if (!self.validateForm()) {
        return;
      }

      self.$swal({
        icon: 'info',
        title: 'Estamos creando el evento...',
        showConfirmButton: false,
      });
      self.$swal.showLoading();
      self.event_cart.bot_id = self.selected_bot.id;

      const filterEvent = self.event_create.filter(e => e.id == id);

      if (filterEvent.length == 0) {
        dashboard_api
          .post('/events', {
            event: self.event_cart,
          })
          .then(result => {
            self.id_event = result.data.id;
            const payrollMessagePromises = self.payroll_message_id.map(e => {
              // Hacer un array de promesas para las peticiones de events_payroll_messages
              return dashboard_api.post('/events_payroll_messages', {
                event_payroll_message: {
                  event_id: self.id_event,
                  payroll_message_id: e,
                  destiny: 'lead_cart_abandoned',
                },
              });
            });

            // Ejecutar todas las peticiones en paralelo usando Promise.all
            return Promise.all(payrollMessagePromises);
          })
          .then(responses => {
            // Obtener las respuestas de las peticiones de events_payroll_messages
            const payrollMessages = responses.map(resp => ({
              payroll_message_id: resp.data.payroll_message_id, // Usar el ID como clave "id"
            }));

            // Crear el objeto del nuevo evento con su respectivo payroll_messages
            const newEvent = {
              id: self.id_event,
              event_name: self.event_cart.event_name,
              active: self.event_cart.active,
              type_event: self.event_cart.type_event,
              type_search: self.event_cart.type_search,
              hour_to_send: self.event_cart.hour_to_send,
              min_price: self.event_cart.min_price,
              max_price: self.event_cart.max_price,
              bot_id: self.event_cart.bot_id,
              events_payroll_messages: payrollMessages,
            };

            // Agregar el nuevo evento al array event_create
            self.event_create.push(newEvent);

            self.$swal({
              icon: 'success',
              title: 'Evento creado para carro abandonado',
              showConfirmButton: false,
              allowOutsideClick: false,
              timer: 1000,
            });

            self.newEventReturn();
          })
          .catch(error => {
            console.error('Error al crear el evento:', error);
          });
      } else {
        dashboard_api
          .put(`/events/${self.event_cart.id}`, {
            event: self.event_cart,
          })
          .then(resp => {
            // Recorre cada payroll_message_id para actualizar los eventos_payroll_messages.
            self.payroll_message_id.forEach(e => {
              if (self.last_payroll_messages !== e) {
                const event = this.event_create.find(event => event.id === Number(self.id_event));
                const relation = event.events_payroll_messages.find(e => e.payroll_message_id === self.last_payroll_messages)
                // Realiza una petición para actualizar el events_payroll_message.
                dashboard_api
                  .put(
                    `/events_payroll_messages/${relation.id}`,
                    {
                      event_payroll_message: {
                        payroll_message_id: e,
                      },
                    },
                  )
                  .then(resp => {
                    self.event_create
                      .find(e => e.id === self.id_event).events_payroll_messages
                      .find(payroll => payroll.destiny === "lead_cart_abandoned").payroll_message_id = self.selectedOption
                    self.last_payroll_messages = self.selectedOption
                  });
              }
            });
            // Muestra un mensaje de éxito.
            self.$swal({
              icon: 'success',
              title: 'Evento ha sido modificado',
              showConfirmButton: false,
              allowOutsideClick: false,
              timer: 1000,
            });
          })
          .catch(error => {
            console.error('Error al actualizar el evento:', error);
          });
      }
    },

    newEventReturn() {
      this.event_cart = {
        event_name: '',
        active: false,
        type_event: 'cart_abandoned',
        type_search: '',
        hour_to_send: '',
        min_price: '',
        max_price: '',
        bot_id: '',
      };
      this.id_event = null;
      this.payroll_message_id = [];
      this.selectedOption = '';
      this.last_payroll_messages = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.carro-abandonado {
  .custom-col {
    margin-top: 2.3rem;
    width: 90%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }
  .cart-label-top {
    border: 1px solid #cdcdcd;
    border-bottom: 0;
    background-color: #f2f2f2;
    border-radius: 1rem 1rem 0 0;
  }
  .info_box {
    margin: 0;
    margin-right: 20px;
    text-align: center;
    padding: 15px 20px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .label-cart {
    padding: 10px 0 0 15px;
  }
  .title-auto {
    font-size: 14px;
    font-weight: 600;
  }
  .info_box-event {
    font-size: 14px !important;
    background-color: #f4f4f4;
    color: #efefef;
    border: 1px solid #cdcdcd;
    border-radius: 0.5rem;

    span {
      font-size: 14px;
      color: #c3c3c3;
    }
  }
  .link {
    font-size: 14px;
    color: #2981ef;
    cursor: pointer;
  }
  .btn-text {
    text-decoration: none !important;
  }
}
.name-event {
  text-align: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #f4f4f4;
}
.dates-row {
  border: 1px solid #cdcdcd;
  border-radius: 0 0 1rem 1rem;
}
.nav-section {
  align-items: baseline;
}
</style>
