<template>
  <section class="detail-id">
    <FadeTransition :duration="200">
      <SpinnerLoading v-if="loading" />
      <div v-else class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <router-link class="btn-text" :to="{ path: `/clients` }"
              >&lt;- Volver al CRM</router-link
            >
            <div class="row mt-5">
              <div class="img_box">
                <!-- <img class="icon" src="/img/icons/no-lead.svg" alt="" /> -->
                <span class="profile-client">{{ leadData.name ? leadData.name[0] : "C" }}</span>
                <div class="d-flex my-4 justify-content-between">
                  <a :href="`mailto:${leadData.email}`">
                    <div class="rrss_circle">
                      <i class="far fa-envelope mx-0" />
                    </div>
                  </a>
                  <a target="_blank" @click="openWhatsapp(leadData.phone)">
                    <div class="rrss_circle">
                      <i class="fab fa-whatsapp mx-0" />
                    </div>
                  </a>
                  <div class="rrss_circle circle_disable">
                    <i class="far fa-calendar-alt mx-0" />
                  </div>
                </div>
              </div>
              <div class="col-lg-8 px-0">
                <h2 class="mb-1">{{ leadData.name }}</h2>
                <p class="mb-2">{{ leadData.email }}</p>
                <div class="d-flex align-items-center mb-3">
                  <p class="mb-0">{{ leadData.phone }}</p>
                  <IconFlag
                    v-if="leadData.phone"
                    classes="ml-2"
                    :phoneNumber="leadData.phone"
                  />
                </div>
                <p class="state">{{ leadData.estado }}</p>
                <p v-if="leadData.custom" class="text_sm mb-1">
                  Sitio web: {{ leadData.custom.url || "--" }}
                </p>
                <p class="text_sm mb-1">Rut: {{ leadData.rut || "--" }}</p>
              </div>
            </div>
            <div class="row my-5 align-items-center">
              <button
                class="btn-option"
                :class="{ active: selected == 1 }"
                @click="selected = 1"
              >
                Información
              </button>
              <button
                class="btn-option"
                :class="{ active: selected == 2 }"
                @click="selected = 2"
              >
                Actividad
              </button>
              <button
                class="btn-option"
                :class="{ active: selected == 3 }"
                @click="selected = 3"
              >
                Notas<span v-if="notes && notes.length > 0" class="badge"
                  >{{ notes.length }}
                </span>
              </button>
              <button
                class="btn-option"
                :class="{ active: selected == 4 }"
                @click="selected = 4"
              >
                Archivos<span v-if="files && files.length > 0" class="badge"
                  >{{ files.length }}
                </span>
              </button>
            </div>

            <div class="info-general" v-show="selected == 1">
              <h3 class="mb-5">Información general</h3>
              <div class="row mt-5">
                <div class="col-lg-6 pl-0">
                  <label>Nombre</label>
                  <input
                    type="text"
                    class="input_2"
                    placeholder="Ingresa un nombre"
                    v-model="leadData.name"
                  />
                  <label>Correo</label>
                  <input
                    type="mail"
                    class="input_2"
                    placeholder="nombre@empresa.cl"
                    v-model="leadData.email"
                  />
                  <label>Teléfono</label>
                  <input
                    type="text"
                    class="input_2"
                    placeholder="+569 1234 5678"
                    v-model="leadData.phone"
                  />
                  <label>Rut</label>
                  <input
                    type="text"
                    class="input_2"
                    placeholder="Ejem: 12.345.678-9"
                    v-model="leadData.rut"
                  />
                  <label>Preferencia de contacto</label>
                  <select v-model="leadData.contact_time">
                    <option value disabled>Seleccionar</option>
                    <option value="Lo antes posible">Lo antes posible</option>
                    <option value="Temprano">AM</option>
                    <option value="Tarde">PM</option>
                  </select>
                </div>
                <div class="col-lg-6 pl-0">
                  <label>Pregunta inicial</label>
                  <input
                    type="text"
                    class="input_2"
                    placeholder="Ingresa una pregunta"
                    v-model="leadData.question"
                  />
                  <label>Canal</label>
                  <p class="txt_disabled-input">{{ setChannelLabel(leadData.channel) }}</p>
                  <!-- <select>
                    <option :value="leadData.channel">{{ leadData.channel }}</option>
                    <option v-for="item in filteredChannels" :value="item">{{ item }}</option>
                  </select> -->
                  <label>Estado</label>
                  <select
                    v-model="leadData.estado"
                    :value="toCapitalizeState()"
                  >
                    <option value disabled>Seleccionar</option>
                    <option value="Pendiente">Nuevo</option>
                    <option value="Contactado">Contactado</option>
                    <option value="Venta Cerrada">Venta Cerrada</option>
                  </select>
                  <label>Agente asignado</label>
                  <template v-if="userbot.length > 0">
                    <select v-model="leadData.agent">
                      <option value="null">Sin asignar</option>
                      <option
                        v-for="userb in userbot"
                        :value="userb.user.name"
                        :key="userb.user.id"
                      >
                        {{ userb.user.name }}
                      </option>
                    </select>
                  </template>
                  <template v-else>
                    <router-link to="/agente/nuevo">
                      <div class="info_box">
                        <p class="mb-0">
                          No tienes agentes creados. ¡Crea tu primer agente!
                        </p>
                      </div>
                    </router-link>
                  </template>
                </div>
              </div>
            </div>
            <div v-show="selected == 2">
              <h3 class="mb-5">Actividad reciente</h3>
              <template v-if="logActivityLead.length == 0">
                <div class="info_box mb-4">
                  <p class="text-loading text-center mb-0">
                    No tienes mensajes de plantilla enviados
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="log_box">
                  <div
                    v-for="log in logActivityLead"
                    :key="log.id"
                    class="info_box mr-1 mb-4"
                  >
                    <!-- MENSAJE DE PLANTILLA -->
                    <template v-if="log.type === 'plantilla'">
                      <div class="row justify-content-between mb-3">
                        <p class="text_sm" v-if="log.type_message">
                          {{ log.type_message }}
                        </p>
                        <p class="text_sm" v-else>Cambio de Agente</p>
                        <p class="text_sm">
                          {{
                            $moment(log.created_at)
                              .locale("es")
                              .format("dddd D MMMM - h:mm a")
                          }}
                        </p>
                      </div>
                      <div v-if="log.type_message">
                        <div class="row justify-content-between my-1">
                          <p>
                            Se le envió el mensaje de plantilla
                            <span class="negrita text-dark">{{
                              log.message_name
                            }}</span
                            >.
                          </p>
                        </div>
                        <p class="text_sm mt-3">
                          Enviado por el agente<span class="negrita-gris">
                            {{ log.agent }}</span
                          >.
                        </p>
                      </div>
                      <div v-else class="row justify-content-between my-1">
                        <p>
                          <span class="negrita text-dark">{{ log.agent }}</span>
                          es el nuevo agente asignado, luego de tomar la
                          conversación.
                        </p>
                      </div>
                    </template>

                    <!-- ACTIVITY LEAD -->
                    <template v-else-if="log.type === 'actividad'">
                      <div class="row justify-content-between mb-3">
                        <p class="text_sm">
                          Creado a través del canal: {{ log.channel }}
                        </p>
                        <p class="text_sm">
                          {{
                            $moment(log.created_at)
                              .locale("es")
                              .format("dddd D MMMM - h:mm a")
                          }}
                        </p>
                      </div>
                      <div class="col justify-content-between my-1">
                        <p>
                          Conversación tomada por:
                          <span class="negrita text-dark">{{
                            log.agent_name
                          }}</span>
                        </p>
                        <p>
                          Duración:
                          <span class="negrita text-dark">{{
                            log.channel === "Manual" ||
                            log.channel === "Whatsapp"
                              ? "-"
                              : `${getMinutes(log.talk_time)} minutos y
                              ${getSeconds(log.talk_time)} segundos`
                          }}</span>
                        </p>
                        <p>
                          Calificación:
                          <span class="negrita text-dark">{{
                            log.channel === "Manual" ||
                            log.channel === "Whatsapp"
                              ? "-"
                              : log.calification == 0 ? "Cliente no califica conversación" : log.calification
                          }}</span>
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
                <!-- <p
                  @click="showAllActivity = true" class="btn-text my-4"
                >Ver todos</p> -->
              </template>
              <br />
            </div>
            <!-- Notas -->
            <div v-show="selected == 3">
              <div class="row justify-content-between mb-5">
                <h3 class="my-0">Notas recientes</h3>
                <span @click="showModalNewNote" class="btn-text my-0"
                  >+ Nueva nota</span
                >
              </div>
              <template v-if="notes && notes.length > 0">
                <div
                  class="card info_box mb-4"
                  v-for="note in notes"
                  :key="note.id"
                >
                  <div class="row justify-content-between">
                    <label class="mb-0">{{ note.title }}</label>
                    <span
                      @click="deleteNote(note.id)"
                      class="text_sm text-danger mb-2"
                      ><i class="fas fa-times" />Eliminar
                    </span>
                  </div>
                  <div class="row justify-content-between">
                    <p class="text_sm">
                      <i class="far fa-calendar-alt" />
                      {{
                        $moment(note.created_at)
                          .locale("es")
                          .format("dddd D MMMM - h:mm a")
                      }}
                    </p>
                    <span
                      @click="editNoteModal(note)"
                      class="text_sm text-primary mb-2"
                      ><i class="fas fa-edit" />Editar nota
                    </span>
                  </div>
                  <p class="mb-0">{{ note.body }}</p>
                </div>
              </template>
              <template v-else>
                <div class="info_box my-5">
                  <p class="text-loading text-center mb-0">
                    Aún no hay notas asociadas a este lead
                  </p>
                </div>
              </template>
              <br />
            </div>
            <!-- Archivos adjuntos -->
            <div v-show="selected == 4">
              <div class="row justify-content-between">
                <h3 class="my-0">Archivos adjuntos</h3>
                <!-- <p class="btn-text my-0">+ Agregar archivo</p> -->
              </div>
              <div v-if="files && files.length > 0" class="col-md-12 px-0">
                <div class="row my-4">
                  <div
                    v-for="item in files"
                    :key="item.id"
                    class="item_file col-md-3 px-2"
                  >
                    <div class="mascara_box">
                      <a :href="item.url" target="_blank">
                        <embed
                          class="imagen"
                          v-if="item.mime_type !== 'application/postscript'"
                          :src="item.url"
                          alt=""
                        />
                        <img
                          class="imagen"
                          v-else
                          src="/img/icons/11-no_file.svg"
                          alt=""
                        />
                      </a>
                    </div>
                    <a :href="item.url" target="_blank" download
                      ><p class="btn-text_file text-center mb-4">
                        {{ item.name }}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div v-else class="info_box my-5">
                <p class="text-loading text-center mb-0">
                  No existen archivos en esta conversación
                </p>
              </div>
            </div>
            <div class="row my-5">
              <button
                class="btn btn-base"
                @click="updateLead"
                v-show="selected == 1"
              >
                Guardar cambios
              </button>
            </div>
          </div>
          <div class="col-lg-5 mt-6 mb-2 mt-mobile">
            <ChatPreview :chat="leadData" :logs="logs" />
          </div>
        </div>
        <br />
        <div class="row my-3 justify-content-center">
          <div class="col-lg-6">
            <router-link class="btn-text" :to="{ path: `/clients` }"
              ><i class="fas fa-arrow-left mr-2" />Volver al CRM</router-link
            >
          </div>
          <div class="col-lg-4" />
        </div>
      </div>
    </FadeTransition>
    <!-- <CalendlyView
      :showModal="viewCalendar"
      @closeModal="viewCalendar = false"
    /> -->
    <AddNewNote
      :showModal="ShowNewNote"
      @closeModal="ShowNewNote = false"
      :newNote="newNote"
      @save="saveNewNote"
    />
    <EditNoteClient
      :showModaledit="ShowEditNote"
      @closeModaledit="ShowEditNote = false"
      :editNoteModal="editNote"
      @update="updateNote"
    />
  </section>
</template>

<script>
import { mapState } from "vuex";
import AddNewNote from "@/components/AddNewNote";
import EditNoteClient from "@/components/EditNoteClient";
import ChatPreview from "@/components/ChatPreview";
import CalendlyView from "@/components/CalendlyView";
import dashboard_api from "@/dashboard_api.js";
import AddTemplate from "../../components/AddTemplate";
import { FadeTransition } from "vue2-transitions";
import IconFlag from '@/components/IconFlag';

export default {
  components: {
    FadeTransition,
    AddTemplate,
    CalendlyView,
    AddNewNote,
    ChatPreview,
    EditNoteClient,
    IconFlag
  },
  data() {
    return {
      showAllActivity: false,
      viewCalendar: false,
      ShowInfo: false,
      ShowActivity: false,
      ShowNotes: false,
      ShowEditNote: false,
      ShowFiles: false,
      ShowNewNote: false,
      selected: 1,
      loading: false,
      leadData: {},
      conversationData: [],
      userbot: [],
      files: [],
      logs: [],
      estado: "",
      notes: [],
      logActivityLead: [],
      newNote: {
        title: "",
        body: "",
      },
      editNote: {
        title: "",
        body: "",
      },
      channels: ["Chatbot", "Whatsapp Bot", "Manual"]
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.infoBotAndLead();
    this.getResumeLeadMessage();
    this.getActivityLead();
  },
  computed: {
    ...mapState(["user", "selected_bot"]),

    filteredChannels() {
      let items = [];
      return items = this.channels.filter(el => el != this.leadData.channel)
    },

    InfoToShow() {
      switch (this.selected) {
        case 1:
          return !this.ShowInfo;
          break;
        case 2:
          return !this.ShowActivity;
          break;
        case 3:
          return !this.ShowNotes;
          break;
        case 4:
          return !this.ShowFiles;
          break;
        default:
          console.log("Listo");
      }
    },
  },
  watch: {
    selected_bot(val) {
      this.$router.push("/login");
    },
  },
  methods: {
    setChannelLabel(name) {
      switch (name) {
        case "Chatbot":
          return "Chatbot web"
        case "Whatsapp Bot":
          return "Chatbot WhatsApp"
        case "Manual":
          return "Carga manual"
        default:
          return name
      }
    },
    editNoteModal(note) {
      this.ShowEditNote = true;
      this.editNote = note;
    },
    updateNote() {
      const self = this;
      dashboard_api
        .patch(`/lead_notes/${self.editNote.id}`, {
          title: self.editNote.title,
          body: self.editNote.body,
          lead_id: self.leadData.id,
        })
        .then((result) => {
          self.$swal({
            icon: "success",
            title: "Nota actualizada",
            timer: 1000,
            showConfirmButton: false,
          });
        });
      self.ShowEditNote = false;
    },
    deleteNote(id) {
      this.$swal({
        title: "¿Eliminar nota?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          dashboard_api.delete(`/lead_notes/${id}`).then((res) => {
            this.notes = this.notes.filter((e) => e.id != id);
            this.$swal({
              icon: "success",
              title: "Nota eliminada",
              timer: 800,
              showConfirmButton: false,
            });
          });
        }
      });
      console.log("Notes here 2 ->", this.notes);
    },
    saveNewNote() {
      const self = this;

      dashboard_api
        .post("/lead_notes", {
          title: self.newNote.title,
          body: self.newNote.body,
          lead_id: self.leadData.id,
        })
        .then((result) => {
          self.notes.unshift(result.data);
          self.$swal({
            icon: "success",
            title: "Nota guardada",
            timer: 1000,
            showConfirmButton: false,
          });
        });
      self.ShowNewNote = false;
      self.newNote.title = "";
      self.newNote.body = "";
      console.log("Push nueva nota", self.notes);
    },
    showModalNewNote() {
      this.ShowNewNote = true;
    },
    toCapitalizeState() {
      if (this.leadData && this.leadData.estado) {
        this.leadData.estado =
          this.leadData.estado.charAt(0).toUpperCase() +
          this.leadData.estado.slice(1);

        // console.log('Aqui estado capitalize ->', this.leadData.estado);
      }
    },
    getResumeLeadMessage() {
      dashboard_api
        .get(
          `/lead_payroll_messages/find_lead_messsage/${this.$route.params.id}`
        )
        .then((res) => {
          const logActivityArray = res.data;
          console.log("plantillas", logActivityArray);
          if (logActivityArray.length > 0) {
            logActivityArray.forEach((element) => {
              element.type = "plantilla";
              element.type_message = "Mensaje Plantilla";
              this.logActivityLead.push(element);
            });
          }
        })
        // .then (resp => {
        //   this.logActivityLead = [...resp, this.logActivityAgent].reverse().flat()
        // })
        .catch((error) => {
          console.log(error);
          // (this.logActivityLead = [])
        })
        .finally(() => {
          this.logActivityLead.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );
        });
    },
    getActivityLead() {
      dashboard_api
        .get(`/activity_leads/find_activity_for_lead/${this.$route.params.id}`)
        .then((res) => {
          const logActivityArray = res.data;
          logActivityArray.forEach((element) => {
            element.type = "actividad";
            this.logActivityLead.push(element);
          });
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.logActivityLead.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );
        });
    },
    async infoBotAndLead() {
      const self = this;
      self.loading = true;

      let resp = (
        await dashboard_api.get(`lead/all_data/${self.$route.params.id}`)
      ).data;

      self.leadData = resp.lead;
      console.log("leadData->", self.leadData);
      self.conversationData = resp.conversation;
      self.files = resp.conversation && resp.conversation.conversation_files;
      self.logs = resp.logs;
      self.notes = resp.lead.lead_notes.reverse();

      dashboard_api
        .get(`/userbot/getallAgent/${self.selected_bot.id}`)
        .then((result) => {
          self.userbot = result.data;
          // console.log('Aqui respuesta userbot ->', self.userbot);
        })
        .catch((error) => console.log("erro -> ", error));
      self.loading = false;
    },
    updateLead() {
      const self = this;

      dashboard_api
        .post("/relief/update_lead", { lead: self.leadData })
        .then((updateLeadResponse) => {
          // self.getLeadInfo(updateLeadResponse.data.id);
          self.$swal({
            icon: "success",
            title: "¡Guardado!",
            showConfirmButton: false,
            timer: 1000,
          });
        })
        .catch((error) => console.log(error));
    },
    openWhatsapp(phone) {
      const res = phone
        .toString()
        .substring(phone.toString().length - 8, phone.toString().length);
      window.open(`https://wa.me/569${res}`, "_blank");
    },
    getMinutes(time) {
      return parseInt(time / 60000);
    },
    getSeconds(time) {
      let seconds = parseInt(time / 1000);
      while (seconds >= 60) {
        seconds -= 60;
      }
      return seconds;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-id {
  width: 100%;
  padding-bottom: 5rem;

  .log_box {
    overflow-y: scroll;
    height: 450px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      transition: 0.5s;
      visibility: visible;
      border-radius: 2rem;
      margin-bottom: 1rem;

      &:hover {
        transition: 0.5s;
        background: #cdcdcd;
      }

      &:active {
        background-color: #cdcdcd;
      }
    }
  }
  .img_box {
    position: relative;
    justify-content: center;
    width: 120px;
    margin-right: 2rem;

    .icon {
      width: 100%;
      height: auto;
    }
    .profile-client {
      min-width: 40px;
      width: 120px;
      height: 120px;
      display: flex;
      border-radius: 50rem;
      background-color: #2981EF;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 45px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .rrss_circle {
      width: 2rem;
      height: 2rem;
      display: flex;
      transition: 0.5s;
      color: #fff;
      align-items: center;
      justify-content: center;
      background-color: #181818;
      border-radius: 100%;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        background-color: #cdcdcd;
      }
    }
    .circle_disable {
      background-color: #cdcdcd;
      cursor: initial;

      &:hover {
        background-color: #cdcdcd;
      }
    }
  }
  .badge {
    color: #fff;
    font-size: 12px;
    background-color: #2981ef;
    border-radius: 5rem;
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
  .state {
    width: 100px;
    font-size: 11px;
    display: flex;
    text-transform: capitalize;
    justify-content: center;
    border: 1px solid #e8e8e8;
    padding: 0.25rem 0.5rem;
    margin: 0.5rem 0 2.5rem;
    border-radius: 0.5rem;
    color: #b2b2b2;

    @media (max-width: 1279px) {
      margin: 0.5rem 0 1.5rem;
    }
  }

  .card_item {
    background-color: #fafafa;

    &:hover {
      background-color: #fff;
    }
  }
  .third-outline {
    width: 160px;

    @media (max-width: 1600px) {
      width: 126px;
    }
  }
  select {
    width: 100%;
  }
  .info-general {
    .info_box {
      border-radius: 0.5rem;
      padding: 1rem;
    }
  }
  option::first-letter {
    text-transform: capitalize;
  }
  .btn-base {
    margin: auto 0;
  }
  .item_file {
    overflow-x: hidden;

    .mascara_box {
      height: 120px;
      border-radius: 8px;
      border: 1px solid #f2f2f2;
      margin-bottom: 1rem;
      overflow: hidden;

      .imagen {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        transition: 0.5s;

        &:hover {
          opacity: 0.75;
        }
      }
    }
    .btn-text_file {
      width: 700;
      color: #767676;
      cursor: pointer;
      transition: 0.5s;
      word-break: break-word;

      &:hover {
        color: #181818;
      }
    }
  }
  .text-danger {
    cursor: pointer;
  }
  .text-primary {
    cursor: pointer;
  }
  .btn-option {
    width: calc((100% / 4) - 0.75rem);
    padding: 0.5rem 1rem;
    margin: 0 0.75rem 0.75rem 0;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0.75rem 0;
    }
  }
}
@media screen and (max-width: 990px) {
  .mt-mobile {
    margin-top: 1rem !important;
  }
}
.fa-times:before {
  color: rgb(255, 0, 0);
}
</style>
