<template>
  <section
    class="automatizaciones"    
  >
    <SpinnerLoading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div>
              <h2 class="mb-4">Automatizaciones</h2>
              <p class="mb-5">
                Puedes enviar una notificacion o link de pago a los clientes que
                aún tengan productos en su carro de compras y no hayan
                concretado el pago
              </p>
            </div>
            <div class="d-flex">
              <router-link
                class="info_box mb-5 col-4 active"
                to="/automatizaciones/carro-abandonado"
                v-if="showCarroAbandonadoShopify"
              >
                <p class="title-auto">Carro <br />abandonado</p>
                <p class="link">Ver</p>
              </router-link>
              <router-link
                v-if="showErrorPago"
                class="info_box mb-5 col-4 active"
                to="/automatizaciones/error-pago"
              >
                <p class="title-auto">Error en el<br />Pago</p>
                <p class="link">Ver</p>
              </router-link>
              <router-link
                v-if="showOrderStatusVtex"
                class="info_box mb-5 col-4 active"
                to="/automatizaciones/seguimiento-pedidos"
              >
                <p class="title-auto">Seguimiento de<br />Pedidos</p>
                <p class="link">Ver</p>
              </router-link>
            </div>
          </div>
          <div class="col-lg-4 justify-content-end d-flex">
            <EventCard :eventCreate="event_create" :disableLoadEvent="true" />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import { mapState } from 'vuex';
import EventCard from '../../views/Automatizaciones/components/EventCard.vue';
import dashboard_api from '../../dashboard_api.js';

export default {
  data() {
    return {
      event_create: [],
      loading: false,
      userCredentials: []
    };
  },

  watch: {
    selected_bot: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getEvents(val);
          await this.getCredentials(val)
        }
      },
    },
  },

  components: {
    EventCard,
  },

  methods: {
    async getEvents(bot) {      
      try {
        this.loading = true;
        let event = await dashboard_api.get(`/events/events_by_bot/${bot.id}`);
        this.event_create = event.data;
        this.loading = false;
      } catch (err) {
        if (err.response.status != 200) {
          this.event_create = [];
        }
      }
    },
    async getCredentials(bot) {
      try {
        this.userCredentials = (await dashboard_api.get(`/user_credentials/search_for_bot/${bot.id}`)).data 
      } catch (error) {
        console.log(error);        
      }
    }
  },

  computed: {
    ...mapState(['selected_bot']),

    showErrorPago() {
      return [756].includes(this.selected_bot.id)
    },
    showOrderStatusVtex(){
      if(!this.userCredentials.length) return false
      return this.userCredentials.some(e=> e.provider==="vtex")
    },
    showCarroAbandonadoShopify(){
      if(!this.userCredentials.length) return false
      return this.userCredentials.some(e=> e.provider==="shopify")
    }
  },
};
</script>

<style lang="scss" scoped>
.automatizaciones {
  .custom-col {
    width: 90%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }

  .info_box,
  .info_box2 {
    margin: 0;
    background-color: #fff;
    margin-right: 20px;
    text-align: center;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border: 1px solid #cdcdcd;
    transition: 0.25s;
  }
  .info_box {
    height: 130px;
  }
  .active:hover {
    border: 1px solid #2981ef;
  }
  .disable {
    pointer-events: none;
  }

  .title-auto {
    font-size: 14px;
    font-weight: 600;
    color: #181818;
  }
  .info_box-event {
    background-color: #f4f4f4;
    color: #efefef;
    border: 1px solid #cdcdcd;
    border-radius: 0.5rem;

    span {
      font-size: 14px;
      color: #c3c3c3;
    }
  }
  .link {
    font-size: 12px;
    text-decoration: none;
  }
}
.scroll_box {
  max-height: 350px;
  padding: 0 5px 0.25rem 0 !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  /* Color de fondo y radius esquinas del thumb */
  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 2rem;

    /* Cambiamos el fondo en hover */
    &:hover {
      background: #cdcdcd;
    }
    /* Cambiamos el fondo en active */
    &:active {
      background-color: #cdcdcd;
    }
  }
  @media (max-width: 1600px) {
    max-height: 260px;
  }
}
.slice_scroll {
  border-bottom: 1px solid #e8e8e8;
}
</style>
