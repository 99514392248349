var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{staticClass:"modal-2",attrs:{"alto":"500","ancho":"600","show":_vm.show},on:{"close":function($event){return _vm.$emit('close')}}},[(_vm.show)?_c('template',{slot:"content"},[_c('section',{staticClass:"mass-tags"},[_c('div',{staticClass:"col-lg-12 p-4 px-5"},[_c('h2',{staticClass:"title-template"},[_vm._v(_vm._s(_vm.addTag ? "Agregar tags a" : "Quitar tags de los")+" "+_vm._s(_vm.filterSelectedIds.length)+" leads.")]),_c('p',[_vm._v("Selecciona los tags que quieras "),_c('span',{staticClass:"negrita"},[_vm._v(_vm._s(_vm.addTag ? "agregar" : "quitar"))]),_vm._v(" a los leads de tu chatbot.")]),_c('div',{staticClass:"row d-flex flex-column justify-content-center my-5"},[_c('div',{staticClass:"row justify-content-center mb-4"},[_c('span',{class:{
                'btn-select mr-2': true,
                'btn-select-active': _vm.addTag
              },on:{"click":function($event){_vm.addTag = true}}},[_vm._v("Agregar")]),_c('span',{class:{
                'btn-select ml-2': true,
                'btn-select-active': !_vm.addTag
              },on:{"click":function($event){_vm.addTag = false}}},[_vm._v("Quitar")])]),_c('div',{staticClass:"info_box bg_grey"},[_c('div',{staticClass:"row justify-content-center align-items-center"},_vm._l((_vm.listTags),function(item){return _c('label',{key:item.id,staticClass:"tag-text text-state text-state-fill my-2 mr-3",style:(`background-color: ${_vm.selected_tags.find(el => el.id == item.id) ? item.label_color : '#cdcdcd'}`),attrs:{"for":item.id}},[_vm._v(_vm._s(item.label_name)+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_tags),expression:"selected_tags"}],staticClass:"not_here",attrs:{"type":"checkbox","id":item.id},domProps:{"value":item,"checked":Array.isArray(_vm.selected_tags)?_vm._i(_vm.selected_tags,item)>-1:(_vm.selected_tags)},on:{"change":function($event){var $$a=_vm.selected_tags,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected_tags=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected_tags=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected_tags=$$c}}}})])}),0),(_vm.selected_tags.length > 0)?_c('span',{staticClass:"text-state text_xs btn bg_grey btn-float",on:{"click":function($event){_vm.selected_tags = []}}},[_vm._v("Limpiar")]):_vm._e()])]),_c('div',{staticClass:"row justify-content-center"},[(_vm.addTag)?_c('button',{staticClass:"btn-base md text-center",attrs:{"disabled":_vm.selected_tags.length == 0},on:{"click":_vm.save}},[_vm._v("Etiquetar leads")]):_c('button',{staticClass:"btn-base md text-center",attrs:{"disabled":_vm.selected_tags.length == 0},on:{"click":_vm.deleteTags}},[_vm._v("Quitar tags")])])])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }