<template>
  <section class="seguimiento-pedidos">
    <SpinnerLoading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-5">
            <router-link class="btn-text" to="/automatizaciones">
              <p class="link">{{ `<- Automatizaciones` }}</p>
            </router-link>
            <div>
              <h2 class="mb-4">
                Seguimiento de pedidos
                <span v-if="event_cart.event_name != ''">
                  - {{ event_cart.event_name }}
                </span>
              </h2>
              <p class="mb-5">
                Crea un evento dentro de la categoría. Este según las
                condiciones que tenga activara las funciones del chatbot.
              </p>
            </div>
            <div>
              <div class="col-lg-12 px-0 buefy">
                <div class="d-flex justify-content-between align-items-end mb-3">
                  <label>Nombre del evento</label>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">Activo</span>
                    <b-switch v-if="event_cart" v-model="event_cart.active" />
                  </div>
                </div>
                <input class="name-event" type="text" maxlength="50" placeholder="Ej: Seguimiento de pedidos 1"
                  v-model="event_cart.event_name" />
              </div>
              <div class="my-5">
                <div class="mb-3">
                  <div class="cart-label-top">
                    <label class="label-cart">Seguimiento
                    </label>
                  </div>
                  <div class="row dates-row py-3 activate-select">
                    <div class="col-lg-12 my-3">
                      <p v-if="selectedStatus.length == 0" class="mb-4">Selecciona en qué estado quieres que se envie tu mensaje de plantilla.</p>
                      <FadeTransition v-else :duration="200">
                        <p>Se enviará el mensaje de plantilla cuando tengan los siguientes estados:
                          <span v-for="(status, index) in selectedStatus" :key="status.value" class="destacado t-regular">{{ status.text }}<span v-if="selectedStatus.length > 1 && index !== selectedStatus.length - 1">, </span>
                        </span>
                        </p>
                      </FadeTransition>
                    </div>
                    <div class="d-flex flex-column col-lg-6">
                      <div class="d-flex justify-content-between align-items-start">
                        <label>Enviar notificación a</label>
                      </div>
                      <Multiselect
                        :titleSelect="'Estados'"
                        :items="allStatus"
                        :selectedsProps="selectedStatus"
                        :showDropdown="showDropdownFilter"
                        @changeDropdown="showCheckboxes"
                        @sendCategoryFilter="handleCategoryFilter" />
                      <div>
                        <div class="custom-select mb-0" @click="showCheckboxes()">
                          <span>
                            Seleccionar Estados
                          </span>
                          <div class="overSelect" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div>
                        <label>Mensaje por enviar</label>
                        <select v-model="selectedTemplate" class="w-100 mb-0">
                          <option value>Seleccionar mensaje</option>
                          <option v-for="template in templates" :key="template.id" :value="template.id">{{ template.name
                          }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between nav-section">
              <router-link class="btn-text" to="/automatizaciones">
                <p class="link">&lt;- Volver</p>
              </router-link>
              <button @click="saveCartEvent(id_event)" class="btn btn-base my-4">
                Guardar
              </button>
            </div>
          </div>
          <div class="col-lg-4 justify-content-end d-flex mb-5">
            <EventCard :eventCreate="event_create" :disableLoadEvent="false" :idEvent="id_event" :hook="true" :botId="selected_bot.id"
              @loadEvent="loadEventById" @newEventReturn="newEventReturn" />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import dashboard_api from '../../dashboard_api.js';
import Multiselect from '../../../src/components/Multiselect';
import EventCard from '../../views/Automatizaciones/components/EventCard.vue';
import { mapState } from 'vuex';
import axios from '../../dashboard_api.js';
import moment from "moment"
import environment from '@/environment.js';
import { FadeTransition } from "vue2-transitions";

export default {
  data() {
    return {
      loading: false,
      id_event: null,
      allTemplate: [],
      event_cart: {
        event_name: '',
        active: false,
        type_event: 'order_status',
        type_search: 'no_search_type',
        hour_to_send: '',
        min_price: '0',
        max_price: '1',
        bot_id: '',
        order_status: []
      },
      payroll_message_id: [],
      payroll_messages: [],
      selectedTemplate: '',
      selectedStatus: [],
      last_payroll_messages: '',
      event_create: [],
      templates: [],
      allStatus: [
        {
          text: 'Pago pendiente',
          value: 'payment-pending',
        },
        {
          text: 'Pago aprobado',
          value: 'payment-approved',
        },
        {
          text: 'Listo para preparación',
          value: 'ready-for-handling',
        },
        {
          text: 'Preparando envío',
          value: 'handling',
        },
        {
          text: 'Facturado',
          value: 'invoiced',
        },
        {
          text: 'Cancelado',
          value: 'canceled',
        },
      ],
      showDropdownFilter: false,
    };
  },

  components: {
    EventCard,
    Multiselect,
    FadeTransition
  },

  watch: {
    selected_bot: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getEvents(val);
          await this.getTemplate(val);
        }
      },
    },
  },

  computed: {
    ...mapState(['selected_bot']),
  },

  methods: {
    // Método para cargar la información del evento
    async loadEventById(id) {
      try {
        this.loading = true;
        const event = this.event_create.find(event => event.id === Number(id));
        if (!event) {
          this.event_cart = {
            event_name: '',
            active: false,
            type_event: 'order_status',
            type_search: 'no_search_type',
            hour_to_send: '',
            min_price: '0',
            max_price: '1',
            bot_id: '',
            order_status: []
          };
          this.id_event = null;
          this.payroll_message_id = [];
          this.selectedTemplate = '';
          this.last_payroll_messages = '';
        } else {
          this.event_cart = event;
          this.id_event = Number(id);
          this.selectedStatus = this.allStatus.filter(e => this.event_cart.order_status.includes(e.value))
          this.selectedTemplate = this.event_cart.events_payroll_messages[0].payroll_message_id;
          this.last_payroll_messages = this.event_cart.events_payroll_messages[0].payroll_message_id;
        }
      } catch (error) {
        console.error('Error al cargar el evento:', error);
      } finally {
        this.loading = false;
      }
    },

    async getTemplate(bot) {
      const response = (
        await dashboard_api.get(`/payroll_messages/by_bot/${bot.id}`)
      ).data;
      this.allTemplate = response;
      if (response.length > 0) {
        this.templates = response.filter(item => {
          return item.components.some(component => {
            const customParams = component.custom_parameters;
            return (
              Array.isArray(customParams) &&
              customParams.includes('$lead.estado_orden')
            );
          });
        });
      }
    },

    // Obtiene todos los eventos
    async getEvents(bot) {
      try {
        this.loading = true;
        let event = await dashboard_api.get(`/events/events_by_bot/${bot.id}`);
        this.event_create = event.data.filter(
          eventItem => eventItem.type_event === 'order_status',
        );
        this.loading = false;
      } catch (err) {
        if (err.response.status != 200) {
          this.event_create = [];
        }
      }
    },

    handleSelectChange(event) {
      // Obtiene el valor seleccionado de la lista desplegable.
      const selectedValue = event.target.value;

      // Verifica si el valor seleccionado no está presente en el array payroll_message_id
      // y si la opción seleccionada (selectedTemplate) no está vacía.
      if (
        !this.payroll_message_id.includes(selectedValue) &&
        this.selectedTemplate !== ''
      ) {
        // Agrega el valor seleccionado al array payroll_message_id.
        this.payroll_message_id.push(selectedValue);
      }
    },

    showCheckboxes() {
      this.showDropdownFilter = !this.showDropdownFilter;
    },

    handleCategoryFilter(event) {
      this.selectedStatus = event;
      this.event_cart.order_status = [];
      this.selectedStatus.forEach(status => {
        this.event_cart.order_status.push(status.value);
      });
    },

    validateForm() {
      if (this.event_cart.event_name === '') {
        this.showError('Ingrese nombre para el evento');
        return false;
      }

      if (this.selectedTemplate === '') {
        this.showError('Debe elegir un mensaje para enviar');
        return false;
      }

      if (this.selectedStatus === '') {
        this.showError('Debe elegir un estado para el evento');
        return false;
      }

      return true;
    },

    showError(message) {
      this.$swal({
        icon: 'error',
        title: message,
      });
    },

    // Salvar el evento
    async saveCartEvent(id) {
      const self = this;

      if (!self.validateForm()) {
        return;
      }

      self.$swal({
        icon: 'info',
        title: 'Estamos creando el evento...',
        showConfirmButton: false,
      });
      self.$swal.showLoading();      

      self.event_cart.bot_id = self.selected_bot.id;
      const filterEvent = self.event_create.filter(e => e.id == id);

      const deleteTimer = localStorage.getItem("deleteTimer")
      const actualTime = new Date()

      // LUEGO DE BORRAR UN HOOK DE VTEX, HAY QUE ESPERAR 60 SEGUNDOS
      if(deleteTimer && moment(actualTime).diff(deleteTimer,'seconds') <= 120){
        self.showError(`No se puede crear el evento, espere ${120 - moment(actualTime).diff(deleteTimer,'seconds')} segundos`)        
        return
      } else {
        localStorage.removeItem("deleteTimer")
      }

      let order_status = []
      this.selectedStatus.forEach(status => {
        order_status.push(status.value);
      });

      if (filterEvent.length == 0) {
        try {
          if(self.event_create.length >= 1){
            self.$swal({
              icon: 'warning',
              title: 'Solo se puede crear un evento',
              showConfirmButton: false,
            });
            return
          }
          await axios.post(`${ environment.url_env_bot }/create-vtex-hook`, { botId: self.selected_bot.id, orderStatus: order_status })
          const eventCreated = await dashboard_api.post("/events", { event: self.event_cart })
          self.id_event = eventCreated.data.id
          const payrollMessages = []
          const payroll = (await dashboard_api.post("/events_payroll_messages",
            {
              event_payroll_message: {
                event_id: self.id_event,
                payroll_message_id: self.selectedTemplate,
                destiny: "no_destiny",
              }
            }
          )).data
          payrollMessages.push({
            id: payroll.id,
            "payroll_message_id": payroll.payroll_message_id,
            "event_id": payroll.event_id,
            "destiny": payroll.destiny
          })
          const newEvent = {
            id: self.id_event,
            event_name: self.event_cart.event_name,
            active: self.event_cart.active,
            type_event: self.event_cart.type_event,
            type_search: self.event_cart.type_search,
            min_price: self.event_cart.min_price,
            max_price: self.event_cart.max_price,
            bot_id: self.event_cart.bot_id,
            events_payroll_messages: payrollMessages,
            order_status
          };
          self.event_create.push(newEvent);

          self.$swal({
            icon: 'success',
            title: 'Evento creado para seguimiento de pedido',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 1000,
          });
          self.newEventReturn();
        } catch (error) {
          console.error(error);
          self.showError("Hubo un error al crear el evento")
        }
      } else {
        try {
          await axios.post(`${ environment.url_env_bot }/create-vtex-hook`, { botId: self.selected_bot.id, orderStatus: order_status })
          await dashboard_api.put(`/events/${self.id_event}`, { event: self.event_cart })
          const event = this.event_create.find(event => event.id === Number(self.id_event));

          if (self.last_payroll_messages !== self.selectedTemplate) {
            try {
              const relation = event.events_payroll_messages.find(e => e.payroll_message_id === self.last_payroll_messages && e.destiny === "no_destiny")
              await dashboard_api.put(`/events_payroll_messages/${relation.id}`,
                {
                  event_payroll_message: {
                    payroll_message_id: self.selectedTemplate,
                  }
                })
              self.event_create
                .find(e => e.id === self.id_event).events_payroll_messages
                .find(payroll => payroll.destiny === "no_destiny").payroll_message_id = self.selectedTemplate
              self.last_payroll_messages = self.selectedTemplate
            } catch (error) {
              self.showError("Hubo un error al editar el evento")
            }
          }

          self.$swal({
            icon: 'success',
            title: 'Evento ha sido modificado',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 1000,
          });
        } catch (error) {
          self.showError("Hubo un error al editar el evento")
          console.error(error);
        }
      }
      localStorage.setItem("creationTimer", moment())
    },

    newEventReturn() {
      this.event_cart = {
        event_name: '',
        active: false,
        type_event: 'order_status',
        type_search: 'no_search_type',
        hour_to_send: '',
        min_price: '0',
        max_price: '1',
        bot_id: '',
      };
      this.id_event = null;
      this.payroll_message_id = [];
      this.selectedTemplate = '';
      this.last_payroll_messages = '';
      this.selectedStatus = []
    },
  },
};
</script>

<style lang="scss" scoped>
.seguimiento-pedidos {
  .custom-col {
    margin-top: 2.3rem;
    width: 90%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }

  .cart-label-top {
    border: 1px solid #cdcdcd;
    border-bottom: 0;
    background-color: #f2f2f2;
    border-radius: 1rem 1rem 0 0;
  }

  .info_box {
    margin: 0;
    margin-right: 20px;
    text-align: center;
    padding: 15px 20px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .label-cart {
    padding: 10px 0 0 15px;
  }

  .title-auto {
    font-size: 14px;
    font-weight: 600;
  }

  .info_box-event {
    font-size: 14px !important;
    background-color: #f4f4f4;
    color: #efefef;
    border: 1px solid #cdcdcd;
    border-radius: 0.5rem;

    span {
      font-size: 14px;
      color: #c3c3c3;
    }
  }

  .link {
    font-size: 14px;
    color: #2981ef;
    cursor: pointer;
  }

  .btn-text {
    text-decoration: none !important;
  }
}

.name-event {
  text-align: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #f4f4f4;
}

.dates-row {
  border: 1px solid #cdcdcd;
  border-radius: 0 0 1rem 1rem;
}

.nav-section {
  align-items: baseline;
}

.custom-select {
  width: 100%;
  overflow: hidden;
}
</style>
