<template>
  <div class="custom-col">
    <label>Eventos creados</label>
    <div v-if="eventCreate.length > 0" class="mt-3">
      <div
        class="scroll_box"
        :class="{ slice_scroll: eventCreate.length >= 3 }"
      >
        <div
          class="card card-click buefy btn-show-sm mb-3"
          :class="{ selected: idEvent === event.id }"
          @click.stop="handleEventClick(event)"
          v-for="(event, index) in eventCreate"
          :key="event.id"
        >
          <div class="d-flex justify-content-between">
            <div class="col-9 col-sm-10 col-lg-8 pl-0">
              <label class="mb-0">{{ event.event_name }}</label>
            </div>
            <div class="col-sm-1 col-lg-2 pr-0">
              <span
                @click.stop="deleteEvent(event.id, index)"
                class="btn-dash btn-dash-sm btn-hover"
              >
                <i class="fas fa-trash-alt m-0" />
              </span>
            </div>
            <div class="col-lg-2 d-flex align-items-center pr-0">
              <b-switch
                v-model="event.active"
                @input="onSwitchChange(event, $event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-lg-6 pl-0">
          <button
            @click="newEventReturn"
            class="btn btn-dash btn-dash-sm m-0"
            v-if="!disableLoadEvent"
          >
            +
          </button>
        </div>
        <div class="col-lg-6 d-flex align-items-center justify-content-end">
          <p class="text_sm mb-0">
            {{ eventCreate.length }} {{ eventCountText }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="info_box-event mb-5 mt-2">
      <div class="row justify-content-center align-items-center mb-0 py-3">
        <span>Sin eventos</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dashboard_api from '../../../dashboard_api';
import moment from 'moment';
import environment from '@/environment.js';

export default {
  props: {
    eventCreate: Array,
    idEvent: Number,
    disableLoadEvent: Boolean,
    botId:Number,
    hook: Boolean
  },
  computed: {
    eventCountText() {
      return this.eventCreate.length === 1 ? 'Evento' : 'Eventos';
    },
  },
  methods: {
    handleEventClick(event) {
      if (!this.disableLoadEvent) {
        this.$emit('loadEvent', event.id);
      }
    },
    onSwitchChange(event, newValue) {
      event.active = newValue;
      if (!newValue) {
        dashboard_api
          .put(`/events/${event.id}`, {
            event: event,
          })
          .then(resp => {
            this.$swal({
              icon: 'error',
              title: `${event.event_name} ha sido desactivado`,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      } else {
        dashboard_api
          .put(`/events/${event.id}`, {
            event: event,
          })
          .then(resp => {
            this.$swal({
              icon: 'success',
              title: `${event.event_name} ha sido activado`,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    },
    deleteEvent(id, index) {
      this.$swal({
        title: '¿Eliminar Evento?',
        text: 'Esta acción es permanentemente y eliminara el evento',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        this.$swal({
          icon: 'info',
          title: 'Estamos eliminando el evento...',
          showConfirmButton: false,
        });
        this.$swal.showLoading();
        if (result.isConfirmed) {
          const creationTimer = localStorage.getItem("creationTimer")
          const actualTime = new Date()

          // LUEGO DE CREAR UN HOOK DE VTEX, HAY QUE ESPERAR 60 SEGUNDOS PARA ELIMINARLO
          if(this.hook && creationTimer && moment(actualTime).diff(creationTimer,'seconds') <= 120){
            this.$swal({
              icon: 'error',
              title: `No se puede eliminar el evento, espere ${120 - moment(actualTime).diff(creationTimer,'seconds')} segundos`
            });                    
            return
          } else {
            localStorage.removeItem("creationTimer")
          }
          if(this.hook){
            axios
              .post(`${ environment.url_env_bot }/delete-vtex-hook`, { botId: this.botId })
              .then(result => {
                localStorage.setItem("deleteTimer", moment())
                dashboard_api
                  .delete(`/events/${id}`)      
                  .then(res=>{
                    this.$swal({
                      icon: 'success',
                      title: 'El evento ha sido eliminado',
                      timer: 1000,
                      showConfirmButton: false,
                    });
                    this.eventCreate.splice(index, 1);
                  })
              })
              .then(resp => {
                if (this.id_event == id) {
                  this.newEventReturn();
                }
              });
          }
          else {
            dashboard_api
              .delete(`/events/${id}`)      
              .then(res=>{
                this.$swal({
                  icon: 'success',
                  title: 'El evento ha sido eliminado',
                  timer: 1000,
                  showConfirmButton: false,
                });
                this.eventCreate.splice(index, 1);
              })            
              .then(resp => {
                if (this.id_event == id) {
                  this.newEventReturn();
                }
              });
          }
        } else {
          console.log('No se borro el evento');
        }
      });
    },
    newEventReturn() {
      this.$emit('newEventReturn');
    },
  },
};
</script>
<style lang="scss" scoped>
.info_box-event {
  font-size: 14px !important;
  background-color: #f4f4f4;
  color: #efefef;
  border: 1px solid #cdcdcd;
  border-radius: 0.5rem;

  span {
    font-size: 14px;
    color: #c3c3c3;
  }
}

.create-event {
  margin: 10px 20px 0 0;
  text-align: center;
  padding: 10px 15px;
  border: 1px solid #cdcdcd;
  background-color: #fff;
  border-radius: 0.5rem;
}
.i-delete,
.i-edit {
  width: 0.7rem;
  cursor: pointer;
}
.scroll_box {
  max-height: 650px;
  padding: 0 5px 0.25rem 0 !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  /* Color de fondo y radius esquinas del thumb */
  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 2rem;

    /* Cambiamos el fondo en hover */
    &:hover {
      background: #cdcdcd;
    }
    /* Cambiamos el fondo en active */
    &:active {
      background-color: #cdcdcd;
    }
  }
  @media (max-width: 1600px) {
    max-height: 490px;
  }
}
.slice_scroll {
  border-bottom: 1px solid #e8e8e8;
}
b-switch {
  z-index: 99;
}
</style>
