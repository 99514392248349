<template>
  <FadeTransition :duracion="100">
    <div v-show="showDropdown" class="multiselect">
      <span class="custom-select" @click="hiddenDropdown">
        Seleccionar {{ titleSelect }}
      </span>
      <div class="scroll_box">
        <label
          v-for="(item, index) in items"
          class="items mb-0"
          :key="index"
          :for="item.name || item.value || item.user.name"
        >
          <input
            class="m-0 mr-3"
            type="checkbox"
            v-model="selecteds"
            :value="item"
            :name="item.name || item.value || item.user.name"
            :id="item.name || item.id || item.value"
          />
          <p class="mb-0">{{ item.name || item.text || item.user.name }}</p>
        </label>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions';

export default {
  components: { FadeTransition },
  props: ['items', 'showDropdown', 'selectedsProps', 'titleSelect'],
  data() {
    return {
      selecteds: [],
      localShowDropdown: this.showDropdown,
    };
  },
  watch: {
    // Observa los cambios en selectedsProps y actualiza selecteds solo si no hay cambios en selectedsProps
    selectedsProps: {
      handler(newVal) {
        if (this.selecteds.length === 0) {
          this.selecteds = newVal;
        }
      },
      deep: true,
    },
    selecteds(val) {
      this.$emit('sendCategoryFilter', val);
    },
  },
  beforeUpdate() {
    // Restablece selecteds si selectedsProps ha cambiado
    if (this.selecteds !== this.selectedsProps) {
      this.selecteds = this.selectedsProps;
    }
  },
  methods: {
    hiddenDropdown() {
      this.localShowDropdown = false;
      this.$emit('changeDropdown');
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect {
  position: absolute;
  width: 97%;
  top: 30%;
  left: 2%;
  padding: 2px 1rem 1rem 1rem;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 10px 30px 30px #deedff;
  background-color: #fff;
  z-index: 100;
}

.items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  transition: 0.25s;
  cursor: pointer;
  &:hover {
    transition: 0.25s;
    background-color: #f2f2f2;
    p {
      color: #181818;
    }
  }
}

select,
.custom-select {
  border: none;
  width: 100%;
  padding: 0.75rem 0;
  margin: 0 0 0 2px;
  
  &:hover,
  &:focus {
    background-image: url('/img/icons/1-arrow-2-rotate.svg');
  }
}

.scroll_box {
  max-height: 500px;
  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 2rem;
    &:hover {
      background: #cdcdcd;
    }
    &:active {
      background-color: #cdcdcd;
    }
  }
}
</style>
