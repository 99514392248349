<template>
  <Modal
    class="modal-2"
    alto="500"
    ancho="600"
    :show="show"
    @close="$emit('close')"
  >
    <template v-if="show" slot="content">
      <section class="mass-tags">
        <div class="col-lg-12 p-4 px-5">
          <h2 class="title-template">{{ addTag ? "Agregar tags a" : "Quitar tags de los" }} {{ filterSelectedIds.length }} leads.</h2>
          <p>Selecciona los tags que quieras <span class="negrita">{{ addTag ? "agregar" : "quitar" }}</span> a los leads de tu chatbot.</p>
          <div class="row d-flex flex-column justify-content-center my-5">
            <div class="row justify-content-center mb-4">
              <span
                @click="addTag = true"
                :class="{
                  'btn-select mr-2': true,
                  'btn-select-active': addTag
                }"
              >Agregar</span>
              <span
                @click="addTag = false"
                :class="{
                  'btn-select ml-2': true,
                  'btn-select-active': !addTag
                }"
              >Quitar</span>
            </div>
            <div class="info_box bg_grey">
              <div class="row justify-content-center align-items-center">
                <label
                  v-for="item in listTags"
                  :key="item.id"                
                  class="tag-text text-state text-state-fill my-2 mr-3"
                  :style="`background-color: ${selected_tags.find(el => el.id == item.id) ? item.label_color : '#cdcdcd'}`"
                  :for="item.id"
                >{{ item.label_name }}
                  <!-- <i v-if="selected_tags.find(el => el.id == item.id)" class="fas fa-times i-custom white m-0 ml-2" /> -->
                  <input class="not_here" type="checkbox" v-model="selected_tags" :value="item" :id="item.id">
                </label>
              </div>
              <span v-if="selected_tags.length > 0" @click="selected_tags = []" class="text-state text_xs btn bg_grey btn-float">Limpiar</span>
            </div>
          </div>
          <div class="row justify-content-center">
            <button v-if="addTag" @click="save" class="btn-base md text-center" :disabled="selected_tags.length == 0">Etiquetar leads</button>
            <button v-else @click="deleteTags" class="btn-base md text-center" :disabled="selected_tags.length == 0">Quitar tags</button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import ModalEtiquetas from '@/components/ModalEtiquetas.vue';
import enviroment from '../environment';
import { mapState } from "vuex";
import axios from 'axios';

export default {
  components: {
    ModalEtiquetas,
  },
  props: ["show", "ids", "listTags", "bot", "rows", "selectedIds"],

  data() {
    return {
      loading: false,
      showDrop: false,
      addTag: true,
      selected_tags: []
    };
  },
  computed: {
    ...mapState(["selected_bot"]),

    filterSelectedIds() {
      let selected_ids = this.selectedIds.map(el => el.id);

      return this.selectedIds && this.selectedIds.length > 0 ? selected_ids : this.ids;
    }
  },
  methods: {
    reset() {
      this.addTag = true;
      this.selected_tags = [];
    },
    async deleteTags() {
      this.$swal({
        title: '¿Quitar tags para todos los leads?',
        icon: 'info',
        text: this.selected_tags.map(el => el.label_name),
        showConfirmButton: true,
        confirmButtonText: 'Quitar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async resp => {
        if (resp.isConfirmed) {
          this.$swal.showLoading();
          let tags_ids = this.selected_tags.map(el => el.id);

          let leads_tags = {
            bot_id: this.selected_bot.id,
            label_ids: tags_ids,
            lead_ids: this.filterSelectedIds
          }
          let resp = await axios.post(`${enviroment.url_massive_tags}/leads_label_bots/delete_massive_relation`, leads_tags);
          console.log("resp->", resp);

          if (this.selectedIds && this.selectedIds.length > 0) {
            this.selectedIds.forEach(lead => {
              let exist = lead.label_leads.map(ele => ele.id);

              this.selected_tags.forEach(tag => {
                if (exist.includes(tag.id)) {
                  lead.label_leads.forEach((item, index, array) => {
                    if (item.id == tag.id) {
                      array.splice(index, 1)
                    }
                  })
                }
              })
            })
          } else {
            this.rows.forEach(lead => {
              let exist = lead.label_leads.map(ele => ele.id);

              this.selected_tags.forEach(tag => {
                // Valida para push solo las tags que no estén en el leads
                if (exist.includes(tag.id)) {
                  lead.label_leads.forEach((item, index, array) => {
                    if (item.id == tag.id) {
                      array.splice(index, 1)
                    }
                  })
                }
              })
            })
          };
          this.$swal({
            title: 'Listo',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          });
          this.reset();
        }
      });
    },
    save() {
      let tags_ids = this.selected_tags.map(el => el.id)

      this.$swal({
        title: '¿Etiquetar todos los leads?',
        icon: 'info',
        text: `Tags seleccionadas: ${this.selected_tags.map(el => el.label_name).join(", ")}.`,
        showConfirmButton: true,
        confirmButtonText: 'Etiquetar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async resp => {
        if (resp.isConfirmed) {
          this.$swal.showLoading();

          let leads_tags = {
            bot_id: this.selected_bot.id,
            labels: tags_ids,
            leads: this.filterSelectedIds
          }

          const massive_tags = axios.create({
            baseURL: enviroment.url_massive_tags,
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
          });

          let resp = await massive_tags.post(
            `/leads_label_bots/create_masive_label_leads`,
              leads_tags
            );
          
          console.log("resp->", resp);

          // Reactividad para row seleccionadas
          if (this.selectedIds && this.selectedIds.length > 0) {
            this.selectedIds.forEach(lead => {
              let exist = lead.label_leads.map(ele => ele.id);

              this.selected_tags.forEach(tag => {
                if (!exist.includes(tag.id)) {
                  lead.label_leads.push(tag)
                }
              })
            })
          } else {
            // Reactividad para toda la tabla
            this.rows.forEach(lead => {
              let exist = lead.label_leads.map(ele => ele.id);

              this.selected_tags.forEach(tag => {
                // Valida para push solo las tags que no estén en el leads
                if (!exist.includes(tag.id)) {
                  lead.label_leads.push(tag)
                }
              })
            });
          }
          this.$swal({
            title: 'Listo',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          });
          this.reset();
        }
      })
    }
  }
};
</script>

<style lang="scss">
// for swal content
.swal2-content {
  .info_box {
    padding: 0.25rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;

    .row {
      padding: 1rem 0;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: none;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .scroll_box {
    width: 100%;
    max-height: 200px;
    padding: 0;
    overflow-y: scroll;

    @media (max-width: 480px) {
      padding: 0.5rem;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;

      /* Cambiamos el fondo en hover */
      &:hover {
        background: #cdcdcd;
      }
      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.mass-tags {
  text-align: left;

  .i-custom {
    position: absolute;
    background-color: #767676;
    border-radius: 5rem;
    padding: .25rem .4rem;
    top: -.6rem;
    right: -.6rem;
  }
  .desactivated {
    background-color: #b2b2b2 !important;
  }
  .btn-float {
    position: absolute;
    right: .5rem;
    bottom: .5rem;
    cursor: pointer;
  }
}
</style>
