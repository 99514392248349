<template>
  <section class="error-pago">
    <SpinnerLoading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-5">
            <router-link class="btn-text" to="/automatizaciones">
              <p class="link">{{ `<- Automatizaciones` }}</p>
            </router-link>
            <div>
              <h2 class="mb-4">
                Error en el pago
                <span v-if="event_cart.event_name != ''"
                  >- {{ event_cart.event_name }}</span
                >
              </h2>
              <p class="mb-5">
                Crea un evento dentro de la categoría. Este según las
                condiciones que tenga activara las funciones del chatbot.
              </p>
            </div>
            <div>
              <div class="col-lg-12 px-0 buefy">
                <div
                  class="d-flex justify-content-between align-items-start mb-3"
                >
                  <label>Nombre del evento</label>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">Activo</span>
                    <b-switch v-if="event_cart" v-model="event_cart.active" />
                  </div>
                </div>
                <input
                  class="name-event"
                  type="text"
                  maxlength="50"
                  placeholder="Ej: Error en el pago 1
                "
                  v-model="event_cart.event_name"
                />
              </div>
              <div class="my-5">
                <div>
                  <div class="cart-label-top">
                    <label class="label-cart">Precio del carrito</label>
                  </div>
                  <div class="row dates-row py-3 activate-select">
                    <div class="d-flex flex-column col-lg-6">
                      <div>
                        <label> Entre </label>
                        <input
                          type="number"
                          placeholder="1,000"
                          v-model.number="event_cart.min_price"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div>
                        <label> Hasta</label>
                        <input
                          type="number"
                          placeholder="100,000"
                          v-model.number="event_cart.max_price"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="cart-label-top">
                    <label class="label-cart">
                      Notifica evento a tu agentes
                    </label>
                  </div>
                  <div class="row dates-row py-3 activate-select">
                    <div class="col-lg-12">
                      <div>
                        <div
                          class="d-flex justify-content-between align-items-start"
                        >
                          <label>Enviar notificación a</label>
                        </div>
                        <Multiselect
                          :titleSelect="titleSelect"
                          :items="usersResume"
                          :selectedsProps="agentsSelect"
                          :showDropdown="showDropdownFilter"
                          @changeDropdown="showCheckboxes"
                          @sendCategoryFilter="handleCategoryFilter"
                        />
                        <div>
                          <div class="custom-select" @click="showCheckboxes()">
                            <span v-if="agentsSelect.length == 0">
                              Seleccionar Agentes
                            </span>
                            <div v-else>
                              <span
                                v-for="agent in agentsSelect"
                                :key="agent.id"
                              >
                                {{ agent.user.name
                                }}<span v-if="agentsSelect.length > 1"
                                  >,
                                </span></span
                              >
                            </div>
                            <div class="overSelect" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div>
                        <div
                          class="d-flex justify-content-between align-items-start"
                        >
                          <label>Mensaje de noticiación</label>
                          <div class="d-flex align-items-center">
                            <router-link to="/whatsapp-business">
                              <span class="link mr-2"
                                >Crear nuevo mensaje -></span
                              >
                            </router-link>
                          </div>
                        </div>
                        <div
                          v-if="templateResp.length == 0"
                          class="info_box-event mb-5 mt-2"
                        >
                          <div
                            class="row justify-content-center align-items-center mb-0 py-3"
                          >
                            <span
                              >Crea un mensaje de plantilla para enviar al
                              agente</span
                            >
                          </div>
                        </div>
                        <div v-else class="mt-2">
                          <select class="col-12" v-model="selectedOptions[0]">
                            <option value="" disabled selected
                              >Selecciona un mensaje para enviar</option
                            >
                            <option
                              v-for="template in templateResp"
                              :key="template.id"
                              :value="template.id"
                              >{{ template.name }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <label>Respuesta para el cliente:</label>
              </div>
              <div>
                <div
                  class="d-flex justify-content-between align-items-start mb-3"
                >
                  <label>Mensaje de respuesta:</label>
                  <div class="d-flex align-items-center">
                    <router-link to="/whatsapp-business">
                      <span class="link mr-2">Crear nuevo mensaje -></span>
                    </router-link>
                  </div>
                </div>
                <div
                  v-if="templateResp.length == 0"
                  class="info_box-event mb-5 mt-2"
                >
                  <div
                    class="row justify-content-center align-items-center mb-0 py-3"
                  >
                    <span
                      >Crea un mensaje de plantilla para respuesta de error de
                      pago</span
                    >
                  </div>
                </div>
                <div v-else>
                  <select class="col-12" v-model="selectedOptions[1]">
                    <option value="" disabled selected
                      >Selecciona un mensaje para enviar</option
                    >
                    <option
                      v-for="template in templateResp"
                      :key="template.id"
                      :value="template.id"
                      >{{ template.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between nav-section">
              <router-link class="btn-text" to="/automatizaciones">
                <p class="link">&lt;- Volver</p>
              </router-link>
              <button
                @click="saveCartEvent(id_event)"
                class="btn btn-base my-4"
              >
                Guardar
              </button>
            </div>
          </div>
          <div class="col-lg-4 justify-content-end d-flex mb-5">
            <EventCard
              :eventCreate="event_create"
              :disableLoadEvent="false"
              :idEvent="id_event"
              @loadEvent="loadEventById"
              @newEventReturn="newEventReturn"
            />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import Multiselect from '../../../src/components/Multiselect';
import EventCard from '../../views/Automatizaciones/components/EventCard.vue';
import dashboard_api from '../../dashboard_api.js';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      titleSelect: 'Agentes',
      id_event: null,
      phone_agent: '',
      message_agent: '',
      allTemplate: [],
      event_cart: {
        event_name: '',
        active: false,
        type_event: 'checkout_cancelled',
        type_search: 'no_search_type',
        min_price: '',
        max_price: '',
        bot_id: '',
        agents: [],
      },
      // payroll_message_id: [],
      payroll_messages: [],
      selectedOptions: ['', ''],
      last_payroll_messages: '',
      last_payroll_messagesAgent: '',
      event_create: [],
      templates: [],
      templateResp: [],
      usersResume: [],
      showDropdownFilter: false,
      agentsSelect: [],
      loading: false,
    };
  },

  components: {
    Multiselect,
    EventCard,
  },

  watch: {
    selected_bot: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getEvents(val.id);
          await this.getTemplate(val.id);
          await this.getResumeAgent(val.id);
        }
      },
    },
  },

  computed: {
    ...mapState(['selected_bot']),
  },

  methods: {
    // Método para cargar la información del evento
    async loadEventById(id) {
      try {
        this.loading = true;
        const event = this.event_create.find(event => event.id === Number(id));
        if (!event) {
          this.event_cart = {
            event_name: '',
            active: false,
            type_event: 'checkout_cancelled',
            type_search: 'no_search_type',
            min_price: '',
            max_price: '',
            bot_id: '',
            agents: [],
          };
          this.id_event = null;
          this.selectedOptions = ['', ''];
          this.last_payroll_messages = '';
          this.last_payroll_messagesAgent = '';
        } else {
          this.event_cart = event;
          this.id_event = Number(id);
          this.selectedOptions = [
            this.event_cart.events_payroll_messages.find(e => e.destiny === "agent_checkout_cancelled").payroll_message_id,
            this.event_cart.events_payroll_messages.find(e => e.destiny === "lead_checkout_cancelled").payroll_message_id,
          ];
          this.updateAgentsSelect();
          this.last_payroll_messagesAgent = this.event_cart.events_payroll_messages.find(e => e.destiny === "agent_checkout_cancelled").payroll_message_id;
          this.last_payroll_messages = this.event_cart.events_payroll_messages.find(e => e.destiny === "lead_checkout_cancelled").payroll_message_id;
        }
      } catch (error) {
        console.error('Error al cargar el evento:', error);
      } finally {
        this.loading = false;
      }
    },

    async getTemplate(bot) {
      this.loading = true;
      const response = (
        await dashboard_api.get(`/payroll_messages/by_bot/${bot}`)
      ).data;

      // Filtrar en templateResp los que contienen "$lead.nombres", "$lead.correo", "$lead.telefono"
      // y los que no tienen custom_parameters
      this.templateResp = response.filter(item => {
        return item.components.every(component => {
          // Verificar si custom_parameters es un array vacío o solo tiene "$lead.nombres", "$lead.correo" o "$lead.telefono"
          const customParams = component.custom_parameters || [];
          return (
            (Array.isArray(customParams) && customParams.length === 0) ||
            customParams.every(
              param =>
                param === '$lead.nombres' ||
                param === '$lead.correo' ||
                param === '$lead.telefono' ||
                param === '$lead.monto' ||
                param === '$lead.nro_orden',
            )
          );
        });
      });

      // Filtrar en templates los custom_parameters que no tienen ningún valor (vengan [])
      this.templates = response.filter(item => {
        return item.components.some(component => {
          // Verificar si custom_parameters es un array vacío
          const customParams = component.custom_parameters;
          return Array.isArray(customParams) && customParams.length === 0;
        });
      });

      this.loading = false;
    },

    updateAgentsSelect() {
      this.agentsSelect = this.usersResume.filter(agent =>
        this.event_cart.agents.find(e => e.user_id == agent.user_id),
      );
    },

    showCheckboxes() {
      this.showDropdownFilter = !this.showDropdownFilter;
    },

    async getResumeAgent(bot) {
      const self = this;
      self.usersResume = (
        await dashboard_api.get(`/userbot/getallAgent/${bot}`)
      ).data;
    },

    // Obtiene todos los eventos
    async getEvents(bot) {
      try {
        this.loading = true;
        let event = await dashboard_api.get(`/events/events_by_bot/${bot}`);
        // Filtrar solo los eventos con type_event igual a "checkout_cancelled"
        this.event_create = event.data.filter(
          eventItem => eventItem.type_event === 'checkout_cancelled',
        );
        this.loading = false;
      } catch (err) {
        if (err.response.status != 200) {
          this.event_create = [];
        }
      }
    },

    handleCategoryFilter(event) {
      this.agentsSelect = event;
      this.event_cart.agents = [];
      this.agentsSelect.forEach(agent => {
        const agentObject = {
          user_id: agent.user.id,
          name: agent.user.name,
          phone: agent.user.phone,
          email: agent.user.email,
        };
        this.event_cart.agents.push(agentObject);
      });
    },

    validateForm() {
      if (this.event_cart.event_name === '') {
        this.showError('Ingrese nombre para el evento');
        return false;
      }

      if (this.event_cart.min_price === '') {
        this.showError('Ingrese el rango mínimo');
        return false;
      }

      if (this.event_cart.max_price === '') {
        this.showError('Ingrese el rango máximo');
        return false;
      }

      if (this.event_cart.max_price < this.event_cart.min_price) {
        this.showError('El rango mayor debe superar al mínimo');
        return false;
      }

      if (this.event_cart.max_price === this.event_cart.min_price) {
        this.showError('Los rangos de precio no pueden ser iguales');
        return false;
      }

      if (this.agentsSelect.length === 0) {
        this.showError('Seleccione un agente para la notificación');
        return false;
      }

      if (this.selectedOptions[0] === '') {
        this.showError('Debe elegir un mensaje para para enviar al agente');
        return false;
      }
      if (this.selectedOptions[1] === '') {
        this.showError('Debe elegir un mensaje de respuesta para enviar');
        return false;
      }
      if (this.selectedOptions[0] === this.selectedOptions[1]) {
        this.showError('Debe elegir diferentes mensajes de plantillas');
        return false;
      }

      return true;
    },

    showError(message) {
      this.$swal({
        icon: 'error',
        title: message,
      });
    },

    // Salvar el evento
    async saveCartEvent(id) {
      const self = this;

      if (!self.validateForm()) {
        return;
      }

      this.showDropdownFilter = false;

      self.$swal({
        icon: 'info',
        title: 'Estamos creando el evento...',
        showConfirmButton: false,
      });
      self.$swal.showLoading();
      self.event_cart.bot_id = self.selected_bot.id;

      const filterEvent = self.event_create.filter(e => e.id == id);

      if (filterEvent.length == 0) {        
        try {
          const eventCreated = await dashboard_api.post("/events", { event: self.event_cart })
          self.id_event = eventCreated.data.id
          const payrollMessages = []
          for (const [index, option] of self.selectedOptions.entries()) {
            const destiny = index === 0 ? 'agent_checkout_cancelled' : 'lead_checkout_cancelled'
            const payroll = (await dashboard_api.post("/events_payroll_messages",
              {
                event_payroll_message: {
                  event_id: self.id_event,
                  payroll_message_id: option,
                  destiny: destiny,
                }
              }
            )).data
            payrollMessages.push({
              id: payroll.id, 
              payroll_message_id: payroll.payroll_message_id,
              event_id: payroll.event_id,
              destiny: payroll.destiny 
            })            
          }
          const newEvent = {
            id: self.id_event,
            event_name: self.event_cart.event_name,
            active: self.event_cart.active,
            type_event: self.event_cart.type_event,
            type_search: self.event_cart.type_search,
            min_price: self.event_cart.min_price,
            max_price: self.event_cart.max_price,
            bot_id: self.event_cart.bot_id,
            events_payroll_messages: payrollMessages,
            agents: self.event_cart.agents,
          };
          self.event_create.push(newEvent);

          self.$swal({
            icon: 'success',
            title: 'Evento creado para error de pago',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 1000,
          });
          self.newEventReturn();
        } catch (error) {
          console.error(error);
          self.showError("Hubo un error al crear el evento")
        }
      } else {
        try {
          await dashboard_api.put(`/events/${self.id_event}`, { event: self.event_cart })
          const event = this.event_create.find(event => event.id === Number(self.id_event));
          
          if (self.last_payroll_messagesAgent !== self.selectedOptions[0]) {
            try {
              const relation = event.events_payroll_messages.find(e => e.payroll_message_id === self.last_payroll_messagesAgent && e.destiny === "agent_checkout_cancelled")              
              await dashboard_api.put(`/events_payroll_messages/${relation.id}`,
              {
                event_payroll_message: {
                  payroll_message_id: self.selectedOptions[0],
                }
              })
              self.event_create
                .find(e => e.id === self.id_event).events_payroll_messages
                .find(payroll=>payroll.destiny ==="agent_checkout_cancelled").payroll_message_id = self.selectedOptions[0]
              self.last_payroll_messagesAgent = self.selectedOptions[0]            
            } catch (error) {
              console.log("error 500 quizas =>", error)
              self.showError("Hubo un error al editar el evento")              
            }
          }
          if(self.last_payroll_messages !== self.selectedOptions[1]){
            const relation = event.events_payroll_messages.find(e => e.payroll_message_id === self.last_payroll_messages && e.destiny === "lead_checkout_cancelled")
            await dashboard_api.put(`/events_payroll_messages/${relation.id}`,
            {
              event_payroll_message: {
                payroll_message_id: self.selectedOptions[1],
              },
            })
            self.event_create
              .find(e => e.id === self.id_event).events_payroll_messages
              .find(payroll => payroll.destiny === "lead_checkout_cancelled").payroll_message_id = self.selectedOptions[1]
            self.last_payroll_messages = self.selectedOptions[1]
          }
          self.$swal({
            icon: 'success',
            title: 'Evento ha sido modificado',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 1000,
          });
        } catch (error) {
          self.showError("Hubo un error al editar el evento")
          console.error(error);
        }
      }
    },

    newEventReturn() {
      this.event_cart = {
        event_name: '',
        active: false,
        type_event: 'checkout_cancelled',
        type_search: 'no_search_type',
        min_price: '',
        max_price: '',
        bot_id: '',
        agents: [],
      };
      this.id_event = null;
      this.selectedOptions = ['', ''];
      this.agentsSelect = [];
      this.last_payroll_messages = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.error-pago {
  .custom-col {
    margin-top: 2.3rem;
    width: 90%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }
  .cart-label-top {
    border: 1px solid #cdcdcd;
    border-bottom: 0;
    background-color: #f2f2f2;
    border-radius: 1rem 1rem 0 0;
  }
  .info_box {
    margin: 0;
    margin-right: 20px;
    text-align: center;
    padding: 15px 20px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .label-cart {
    padding: 10px 0 0 15px;
  }
  .title-auto {
    font-size: 14px;
    font-weight: 600;
  }
  .info_box-event {
    font-size: 14px !important;
    background-color: #f4f4f4;
    color: #efefef;
    border: 1px solid #cdcdcd;
    border-radius: 0.5rem;

    span {
      font-size: 14px;
      color: #c3c3c3;
    }
  }
  .link {
    font-size: 14px;
    color: #2981ef;
    cursor: pointer;
  }
  .btn-text {
    text-decoration: none !important;
  }
}
.name-event {
  text-align: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #f4f4f4;
}
.dates-row {
  border: 1px solid #cdcdcd;
  border-radius: 0 0 1rem 1rem;
}
.nav-section {
  align-items: baseline;
}
.custom-select {
  width: 100%;
}
</style>
